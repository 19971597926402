<template>
  <v-row
    id="kundenstimmen"
    class="mb-4"
    justify="center"
  >
    <v-col
      cols="12"
      class="text-center mb-6"
    >
      <h2 class="text-h5 text-sm-h4 text-md-h3 font-weight-bold">
        Was unsere Kunden sagen
      </h2>
      <p class="text-body-2 text-sm-subtitle-1 mt-2">
        Erfahren Sie, was unsere Kunden von uns hören
      </p>
    </v-col>
    <v-col
      v-for="testimonial in testimonials"
      :key="testimonial.name"
      cols="12"
      sm="6"
      md="4"
      class="mb-4 mb-sm-0"
    >
      <v-card height="100%">
        <v-card-text>
          <div class="d-flex align-center mb-4">
            <v-avatar
              size="48"
              class="mr-4"
            >
              <v-img
                :src="testimonial.avatar"
                :alt="testimonial.name"
              ></v-img>
            </v-avatar>
            <div>
              <div class="text-subtitle-2 text-sm-subtitle-1">{{ testimonial.name }}</div>
              <div class="text-caption text-sm-subtitle-2">{{ testimonial.title }}</div>
            </div>
          </div>
          <p class="text-body-2 text-sm-body-1">{{ testimonial.quote }}</p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
const testimonials = [
  {
    name: 'Sarah J.',
    title: 'Salonbesitzerin',
    quote: '"Endlich kann ich mich auf meine Kunden konzentrieren und nicht mehr auf die Terminverwaltung. TerminTiger ist eine echte Erleichterung."',
    avatar: '/images/avatars/avatar-4.png',
    rating: 5
  },
  {
    name: 'Michael T.',
    title: 'Spa-Manager',
    quote: '"Die Funktion mit dem Erinnern an das Neubuchen der Termine begeistert uns immer wieder aufs Neue. Wir müssen uns darum nicht mehr kümmern."',
    avatar: '/images/avatars/avatar-5.png',
    rating: 5
  },
  {
    name: 'Emma L.',
    title: 'Kosmetikerin',
    quote: '"Seit wir TerminTiger nutzen, haben wir nicht nur mehr Buchungen, sondern auch 25% weniger Terminausfälle. Absolut empfehlenswert!"',
    avatar: '/images/avatars/avatar-8.png',
    rating: 5
  }
]
</script>
