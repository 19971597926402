<template>
  <v-col
    v-for="plan in pricingPlans"
    :key="plan.title"
    cols="12"
    sm="6"
    md="4"
  >
    <v-card
      height="100%"
      :class="['d-flex', 'flex-column', 'pricing-plan', 'position-relative', { 'bg-white': !plan.popular }, { 'bg-secondary-lighten-5': plan.popular }]"
      :variant="plan.popular ? 'elevated' : 'outlined'"
      hover
    >
      <v-chip
        v-if="plan.popular"
        color="primary"
        class="position-absolute top-0 right-0 mt-5 mr-5"
      >
        <v-icon
          start
          size="small"
        >ri-star-fill</v-icon>
        Beliebt
      </v-chip>
      <v-card-title class="justify-center text-h5 text-sm-h4 font-weight-bold pt-8 text-center">
        {{ plan.title }}
      </v-card-title>
      <v-card-subtitle class="text-center pb-0 text-body-2 text-sm-subtitle-1">{{ plan.description
        }}</v-card-subtitle>
      <v-card-text class="flex-grow-1 d-flex flex-column justify-space-between">
        <div>
          <div class="text-h3 text-sm-h2 font-weight-bold mb-2 text-center">
            <span class="text-h6 text-sm-h5 align-top">€</span>{{ plan.price }}
            <span class="text-caption">/Monat</span>
          </div>
          <v-divider class="my-6"></v-divider>
          <v-list
            density="compact"
            bg-color="transparent"
            role="list"
          >
            <v-list-item
              v-for="feature in plan.features"
              :key="feature"
              class="px-0 py-2"
              role="listitem"
            >
              <template v-slot:prepend>
                <v-icon
                  color="success"
                  size="small"
                >ri-check-line</v-icon>
              </template>
              <v-list-item-title class="text-body-2">{{ feature }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center pb-8">
        <v-btn
          size="large"
          :color="plan.popular ? 'primary' : 'secondary'"
          :variant="plan.popular ? 'tonal' : 'tonal'"
          class="text-subtitle-2 text-sm-subtitle-1 font-weight-bold"
          @click="selectPlan(plan)"
        >
          <v-icon start>ri-arrow-right-line</v-icon>
          {{ plan.buttonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>


<script lang="ts" setup>
import { pricingPlans } from '@/types/constants'

const selectPlan = (plan: any) => {
  window.location.href = '/auth/register?plan=' + plan.title
}
</script>

<style scoped lang="scss">
.pricing-plan {
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.pricing-plan:hover {
  transform: translateY(-5px);
  box-shadow: 0 0px 24px rgba(126, 141, 133, 0.15); // Using secondary color with opacity
}

.pricing-plan.bg-white:hover {
  box-shadow: 0 0px 24px rgba(81, 81, 81, 0.15); // Using a more grey color with opacity
}

</style>