<template>
  <NuxtLayout name="default-public">
    <!-- header  -->
    <template #hero>
      <v-container
        :fluid="false"
        :max-width="1280"
      >
        <v-row>
          <v-col
            cols="12"
            md="8"
            id="header"
            class="mx-auto"
          >
            <div class="pa-md-10 pa-5 text-center">
              <h1 class="text-white text-h4 text-sm-h4 text-md-h4 text-lg-h3 font-weight-bold mb-4 mb-sm-6">
                Terminplanung und Aquise, steigern Sie Ihren Umsatz um <span class="highlight-text">bis zu
                  30%!</span>
              </h1>
              <p class="text-white text-body-2 text-sm-body-1 text-md-h6 mb-6 mb-sm-10 text-grey-darken-2">
                Unsere Software verbessert Ihre Terminplanung und Kundenverwaltung. Machen Sie Ihr Geschäft
                erfolgreicher
                mit unserem Online Kalender und profitieren Sie von der Reichweite!
              </p>
              <v-form
                @submit.prevent="submitForm"
                class="mb-4 mb-sm-6"
              >
                <v-row
                  justify="center"
                  no-gutters
                >
                  <v-col
                    cols="12"
                    sm="10"
                    md="8"
                    lg="7"
                    xl="6"
                    class="px-4"
                  >
                    <v-text-field
                      v-model.trim="formState.email"
                      label="E-Mail-Adresse"
                      type="email"
                      required
                      variant="outlined"
                      density="comfortable"
                      :key="'email-field-header'"
                      bg-color="white"
                      name="email"
                      hide-details="auto"
                      prepend-inner-icon="ri-mail-line"
                      class="mb-4"
                      @blur="v$.email.$touch"
                      :error="v$.email.$invalid && v$.name.$dirty"
                      :error-messages="v$.email.$errors.map(e => e.$message).join(', ')"
                    />
                    <Button
                      type="submit"
                      color="accent"
                      size="x-large"
                      class="text-h6 text-sm-h5 text-md-h5"
                      :loading="isSubmitting"
                    >
                      Kostenlos testen
                    </Button>
                  </v-col>
                </v-row>
              </v-form>
              <p class="text-caption text-sm-body-2 text-xs text-white">
                <b>Ohne Risiko:</b> 30 Tage Geld-zurück-Garantie, keine Kreditkarte nötig
              </p>
              <!-- <v-chip
            color="secondary"
            text-color="white"
            class="mt-4"
          >
            <v-icon start>ri-star-line</v-icon>
            Über 1000 zufriedene Kunden
          </v-chip> -->
            </div>

          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- features -->
    <v-row
      id="funktionen"
      class="mb-md-16 mb-2"
    >
      <v-col
        cols="12"
        class="text-center"
      >
        <h2 class="text-h5 text-sm-h4 text-md-h3 font-weight-bold">
          Innovative Funktionen für Ihr Geschäft
        </h2>
        <p class="text-body-2 text-sm-subtitle-1 text-md-h6 mt-2">
          Entdecken Sie, wie unser Online Kalender und andere Tools Ihren Arbeitsalltag revolutionieren
        </p>
      </v-col>

      <v-col
        v-for="feature in features"
        :key="feature.title"
        cols="12"
        sm="12"
        md="6"
        class="mb-4"
      >
        <FeatureCard :feature="feature" />
      </v-col>
    </v-row>

    <!-- callback -->
    <v-row
      no-gutters
      class="text-center mb-md-16 mb-2"
    >
      <v-col cols="12">
        <h2 class="text-h5 text-sm-h4 text-md-h3 font-weight-bold mb-4">
          Terminplanung leicht gemacht
        </h2>
        <p class="text-body-2 text-sm-body-1 text-md-h6 mb-6 mb-sm-8">
          Sie haben noch Fragen? Wir rufen Sie gerne zurück und beraten Sie persönlich.
        </p>
        <v-form @submit.prevent="requestCallback">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
              class="mx-auto"
            >
              <v-text-field
                v-model.trim="formState.name"
                label="Ihr Name"
                type="tel"
                required
                variant="outlined"
                density="comfortable"
                :key="'phone-field-callback'"
                class="mb-4"
                name="name"
                @blur="v$.name.$touch"
                :error="v$.name.$invalid && v$.name.$dirty"
                :error-messages="v$.name.$errors.map(e => e.$message).join(', ')"
              >
              </v-text-field>
              <v-text-field
                v-model.trim="formState.phone"
                label="Ihre Telefonnummer"
                type="tel"
                required
                variant="outlined"
                density="comfortable"
                :key="'phone-field-callback'"
                class="mb-4"
                name="phone"
                @blur="v$.phone.$touch"
                :error="v$.phone.$invalid && v$.phone.$dirty"
                :error-messages="v$.phone.$errors.map((e: any) => e.$message).join(', ')"
              >
              </v-text-field>

              <Button
                type="submit"
                color="accent"
                size="x-large"
                block
                class="px-8 text-h6 text-sm-h5 text-md-h5"
                :disabled="v$.phone.$invalid || v$.name.$invalid"
              >
                Rückruf anfordern
              </Button>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>


    <!-- pricing -->
    <v-row
      id="preise"
      justify="center"
      no-gutters
    >
      <v-col
        cols="12"
        class="text-center mb-6 mb-sm-8"
      >
        <h2 class="text-h5 text-sm-h4 text-md-h3 font-weight-bold">
          Maßgeschneiderte Preispläne für Ihr Geschäft
        </h2>
        <p class="text-body-2 text-sm-subtitle-1 mt-2">Wählen Sie den Plan, der am besten zu Ihren Bedürfnissen passt
        </p>
      </v-col>
    </v-row>

    <!-- price block -->
    <v-row justify="center">
      <PriceBlock />
    </v-row>

    <!-- faq -->
    <v-row
      class="mb-md-16 mb-2"
      justify="center"
      no-gutters
    >
      <v-col
        cols="12"
        class="text-center mt-4"
      >
        <p class="text-body-2 text-sm-subtitle-1">
          Alle Pläne beinhalten eine 30-Tage-Geld-zurück-Garantie. Testen Sie risikofrei!
          <br><span class="text-caption text-sm-body-2">Alle Preise zzgl. 19% Mehrwertsteuer</span>
        </p>
        <Button
          variant="text"
          color="primary"
          class="mt-2"
          :to="{ name: 'faq' }"
        >Häufig gestellte Fragen</Button>
      </v-col>
    </v-row>

    <!-- testimonials -->
    <CustomerFeedback />

    <!-- cta -->
    <v-row
      no-gutters
      justify="center"
    >
      <v-card
        cols="12"
        md="8"
        class="pa-10 text-center"
      >
        <h4 class="text-h4 text-sm-h3 text-md-h3 font-weight-bold mb-6">
          Bereit, Ihr Geschäft zu transformieren?
        </h4>
        <p class="text-body-1 text-sm-h6 text-md-h5 mb-8 mb-sm-10">
          Schließen Sie sich Tausenden zufriedener Profis an und steigern Sie Ihre Effizienz um bis zu 30%.
          Starten Sie noch heute Ihre Testphase mit 30-Tage-Geld-zurück-Garantie.
        </p>
        <v-form
          @submit.prevent="submitForm"
          class="mb-6"
        >
          <v-row
            justify="center"
            align="center"
            no-gutters
          >
            <v-col
              cols="12"
              sm="6"
              md="5"
              class="pr-sm-2 mb-4 mb-sm-0"
            >
              <v-text-field
                v-model.trim="formState.email"
                label="Ihre E-Mail-Adresse"
                type="email"
                required
                color="default"
                variant="outlined"
                density="comfortable"
                name="email"
                :key="'email-field-footer'"
                prepend-inner-icon="ri-mail-line"
                @blur="v$.email.$touch"
                :error="v$.email.$invalid && v$.name.$dirty"
                :error-messages="v$.email.$errors.map(e => e.$message).join(', ')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="auto"
            >
              <Button
                type="submit"
                color="secondary"
                size="x-large"
                :disabled="v$.email.$invalid"
                class="text-h6 text-sm-h5"
              >
                <v-icon>ri-rocket-2-line</v-icon>
                Jetzt starten
              </Button>
            </v-col>
          </v-row>
        </v-form>
        <span class="mt-4 text-white text-body-2 text-sm-body-1">
          <v-icon start>ri-shield-check-line</v-icon>
          Keine Kreditkarte erforderlich. 30 Tage Geld-zurück-Garantie.
        </span>
      </v-card>
    </v-row>


    <LinkBlockAroundYou />
  </NuxtLayout>

</template>

<script setup lang="ts">
import Button from '~/components/Button.vue';
import CustomerFeedback from '~/components/public/CustomerFeedback.vue';
import PriceBlock from '~/components/public/PriceBlock.vue';
import FeatureCard from '~/components/public/FeatureCard.vue';
import { useVuelidate } from '@vuelidate/core'
import { required, email, numeric, helpers } from '@vuelidate/validators'
import LinkBlockAroundYou from '~/components/public/LinkBlockAroundYou.vue';

const isSubmitting = ref(false)
const formState = reactive({
  name: '',
  phone: '',
  email: ''
});

const v$ = useVuelidate({
  email: {
    required: helpers.withMessage('Die E-Mail-Adresse muss eingegegebn werden', required),
    email: helpers.withMessage('Format der E-Mail-Adresse ist nicht korrekt', email)
  },
  phone: {
    required: helpers.withMessage('Die Telefonnummer muss eingegeben werden', required),
    numeric: helpers.withMessage('Die Telefonnummer darf nur Zahlen enthalten', numeric)
  },
  name: {
    required: helpers.withMessage('Der Name muss eingegeben werden', required)
  }
}, formState);

definePageMeta({
  layout: false
});

useHead({
  title: 'TerminTiger - Revolutionäre Terminplanung für Ihre Branche | Bis zu 30% mehr Effizienz',
  meta: [
    {
      name: 'description',
      content: 'TerminTiger: Die All-in-One-SaaS-Lösung für Ihre Branche. Optimieren Sie Ihre Terminplanung, steigern Sie Ihre Effizienz um bis zu 30% und verbessern Sie Ihr Kundenmanagement mit unserem Online Kalender. Jetzt 30 Tage kostenlos testen!'
    },
    {
      name: 'keywords',
      content: 'Terminplanung, Kundenverwaltung, SaaS, Effizienzsteigerung, Online-Kalender'
    },
    {
      property: 'og:title',
      content: 'TerminTiger - Revolutionäre Terminplanung für Ihre Branche'
    },
    {
      property: 'og:description',
      content: 'Steigern Sie Ihre Effizienz um bis zu 30% mit unserer All-in-One-Lösung für Terminplanung und Kundenmanagement. Nutzen Sie unseren Online Kalender für maximale Produktivität.'
    },
    {
      name: 'twitter:title',
      content: 'TerminTiger - Revolutionäre Terminplanung für Ihre Branche'
    },
    {
      name: 'twitter:description',
      content: 'Optimieren Sie Ihr Geschäft mit TerminTiger. Bis zu 30% mehr Effizienz durch effiziente Terminplanung und verbessertes Kundenmanagement mit unserem Online Kalender.'
    }
  ]
})

const features = [
  {
    image: "/images/features/terminplaner-showcase.jpg",
    title: 'Intelligente Terminplanung',
    description: 'Optimieren Sie Ihren Zeitplan mit KI-gestützter Terminvergabe. Reduzieren Sie Ausfälle um bis zu 50% durch automatisierte Erinnerungen und flexible Buchungsoptionen.',
    // benefit: 'Steigern Sie Ihre Effizienz um bis zu 30%'
  },
  {
    image: "/images/features/24_7_neue_kunden.jpeg",
    title: 'Autonome Kundenaquise',
    description: 'Gewinnen Sie neue Kunden durch Online-Buchungen und verbessern Sie die Kundenbindung durch personalisierte Erinnerungen und Angebote.',
    // benefit: 'Erhöhen Sie die Kundenbindung um bis zu 40%'
  },
  {
    image: "/images/features/voller-terminkalender.jpg",
    title: 'Voller Terminkalender',
    description: 'Verwalten Sie Ihre Termine und Kundenkontakte zentral und effizient. Nutzen Sie die automatisierte Terminvergabe und reduzieren Sie Ausfallzeiten.',
    // benefit: 'Verbessern Sie die Teamproduktivität um bis zu 30%'
  },
  {
    image: "/images/features/datadriven.jpg",
    video: "/videos/termin-gebucht-mobile",
    title: 'Mehr Zeit für Ihre Kunden',
    description: 'Ihr Kalender füllt sich von alleine, während Sie sich auf Ihre Kunden konzentrieren. Nutzen Sie die Zeitersparnis für individuelle Beratung und Service. Die Kunden buchen die Termin direkt in Ihrem Kalender und Sie können sich auf Ihre Arbeit konzentrieren.',
    // benefit: 'Steigern Sie Ihren Umsatz um bis zu 20%'
  }
]

const submitForm = () => {
  try {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    dataLayer.push({
      'event': 'start_registration',
      'app_name': 'TerminTiger',
      'screen_name': 'Home',
      'email': formState.email,
    });
  } catch (error) {
    console.error('Error tracking form submission', error);
  }

  window.location.href = '/auth/register?email=' + formState.email
}

const requestCallback = async () => {
  if (v$.value.phone.$invalid || v$.value.name.$invalid) {
    return;
  }

  await $fetch('/api/callback', {
    method: 'POST',
    body: { phone: formState.phone, name: formState.name }
  });

  formState.phone = '';
  formState.name = '';

  v$.value.$reset();
}

</script>

<style lang="scss" scoped>
.highlight-text {
  background-color: #fff;
  padding: 2px 4px 0px 4px;
  display: inline-block;
  border-radius: 4px;
  color: rgb(var(--v-theme-primary));
}
</style>

<style lang="scss">
.hero-section {
  .v-btn--variant-tonal .v-btn__content {
    color: white !important;
    z-index: 10;
  }

  .v-btn--variant-tonal .v-btn__underlay {
    background-color: rgb(var(--v-theme-accent)) !important;
    opacity: 1;
    z-index: 0;
  }


}
</style>